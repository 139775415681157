<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.branchId.$error }">
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="entity.branchId" name="branchId" id="branchId">
                                    <md-option value="0">Chọn chi nhánh</md-option>
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                                <span class="md-error" v-if="submitted && !$v.entity.branchId.isSelected">Vui lòng chọn chi nhánh</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.code.$error }">
                                <label for="code">Mã phòng ban</label>
                                <md-input name="code" v-model="entity.code"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.code.required">Vui lòng nhập mã phòng ban</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.name.$error }">
                                <label for="name">Tên phòng ban</label>
                                <md-input name="name" v-model="entity.name"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.name.required">Vui lòng nhập tên phòng ban</span>
                            </md-field>

                            <div class="form-control">
                                <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs">
                                    <label>Trưởng phòng/phê duyệt</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openStaff()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm nhân viên</md-tooltip>
                                </md-button>
                            </div>

                            <md-field>
                                <label for="note">Ghi chú</label>
                                <md-input name="note" v-model="entity.note" ></md-input>
                            </md-field>

                            <div class="form-control">
                                <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <staffList ref="staffList" @close="closeStaff" />
   </div>
</template>
<script>
    import departmentService from '../../../api/departmentService';
    import staffService from '../../../api/staffService';
    import branchService from '../../../api/branchService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';
    import staffList from '../../../components/popup/_StaffList.vue';

    export default ({
        components: {
            staffList
        },
        metaInfo: {
            title: 'Thêm/Cập nhật phòng ban'
        },
        data() {
            return {
                title: '',
                id: 0,
                submitted: false,
                entity: { id: 0, branchId: 0, managerId: null, code: '', name: '', note: '', isActive: true },
                branchs: [],
                staffName: '',
                staffs: []
            }
        },
        created(){
            this.id = this.$route.params.id;
            this.getBranchs();
            if(this.id > 0){
               this.title = 'Cập nhật phòng ban';
               this.getById();
            }
            else{
               this.title = 'Thêm mới phòng ban';
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            //Staff
            closeStaff(item){
                this.staffName = item.fullName;
                this.entity.managerId = item.id;
                this.$refs.staffList.close();
            },

            getStaffSelected(val){
                this.entity.managerId = val.id;
                this.staffName = val.fullName;
            },

            openStaff(){
                this.$refs.staffList.open();
            },

            getStaffs(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, deptId: 0, code: '', name: searchTerm };
                staffService.getStaffs(search).then((response) => {
                    if(response.statusCode == 200){
                        this.staffs = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                departmentService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                departmentService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/department');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                departmentService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            back(){
                this.$router.push('/department');
            },
        },
        watch: { 
            staffName: function (val) { 
                if(val == ''){
                    this.entity.managerId = '';
                }
            },
        },
        validations: {
            entity: {
                branchId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                code: { required },
                name: { required }
            }
        }
    })

</script>
